import React, { useContext } from 'react'
import { graphql } from 'gatsby'
import Process from '../components/Process'
import Layout from '../components/Global/Layout'
import Loader from '../components/Global/Loader'
import { GlobalStateContext } from '../context/GlobalContextProvider'

export const query = graphql`
  {
    allContentfulTrademarkStory {
      edges {
        node {
          title
          subtitle
          storyText {
            storyText
          }
          quote {
            internal {
              content
            }
          }
          image {
            fluid(maxWidth: 1000, quality: 100) {
              ...GatsbyContentfulFluid
            }
          }
          category
          node_locale
          storyLocale {
            internal {
              content
            }
          }
        }
      }
    }

    allContentfulProcessLanding {
      edges {
        node {
          title
          dropdownHeading
          startStoryButton
          accordionTitle
          accordionHeadings
          accordionContent {
            internal {
              content
            }
          }
          timelineText
          getStartedCard {
            internal {
              content
            }
          }
          seoContent {
            internal {
              content
            }
          }
          node_locale
        }
      }
    }
  }
`

const ProcessPage = (props) => {
  const state = useContext(GlobalStateContext)

  if (!state.locale) {
    return (
      <Layout url='/process' locale={state.locale}>
        <Loader loading />
      </Layout>
    )
  }
  const stories = props.data?.allContentfulTrademarkStory?.edges.filter(
    (node) => {
      return (
        node.node.node_locale === state.locale &&
        JSON.parse(node.node.storyLocale.internal.content)[`${state.locale}`]
      )
    }
  )
  const pageText = props.data?.allContentfulProcessLanding?.edges.filter(
    (node) => node.node.node_locale === state.locale
  )[0].node

  return (
    <Layout
      locale={state.locale}
      url='/process'
      title={JSON.parse(pageText?.seoContent.internal.content).title}
      desc={JSON.parse(pageText?.seoContent.internal.content).desc}
    >
      <Process stories={stories} pageText={pageText} locale={state.locale} />
    </Layout>
  )
}

export default ProcessPage
